import React from "react";
import { connect } from "react-redux";
import MercurialFileModal from "./MercurialFileModal";
import MercurialColumnsModal from "./MercurialColumnsModal";
import MercurialImgsModal from "./MercurialImgsModal";
import {
  addMercurial,
  addProductsImgs,
  deleteMercurial,
} from "../../actions/mercurials/mercurials";
import MercurialRow from "./MercurialRow";
import ExcelUtil from "../../util/ExcelUtil";
import AlertModal from "../sub/modals/AlertModal";
import MercurialStatus from "../../enums/MercurialStatus";
import { updateMercurialStatus } from "../../actions/mercurials/mercurials";
import { FormattedMessage, injectIntl } from "react-intl";
import Paginator from "../sub/Paginator";
import MercurialArchiveModal from "./MercurialArchiveModal";
import MercurialsSyncArticlesModal from "./MercurialsSyncArticlesModal";
import DateUtil from "../../util/DateUtil";
import Util from "../../util/Util";
import { findArticlesMust } from "../../actions/apiMust/apiMust";
import Notification from "../sub/Notification";
import MenuButton from "../sub/bootstrap/MenuButton";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import { Alert, Col, Form, FormSelect, Row } from "react-bootstrap";
import FamiliesAdmin from "../families/FamiliesAdmin";
import { addFamilyImg } from "../../actions/families/families";
import InputTextElement from "../sub/InputTextElement";
import ConfirmationModal from "../sub/modals/ConfirmationModal";

class Mercurials extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      isDeleteCatalog: false,
      // Import data
      mercurialName: null,
      fileName: null,
      fileData: null,
      startDate: null,
      endDate: null,
      columns: null,
      fileError: null,
      // Filters
      nameFilter: "",
      startDateFilter: "",
      endDateFilter: "",
      mercurialsFilter: MercurialStatus.ACTIVE.toString(),
      mode: "create",
      mercurialId: "",
    };

    this.paginator = new Paginator(this);
  }

  // First modal when importing a new mercurial
  openMercurialImportModal(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      mode: "create",
      modal: (
        <MercurialFileModal
          closeModal={() => this.abortAndCloseModal()}
          onComplete={(mercurialName, fileData, startDate, endDate) =>
            this.onCompleteStep1(mercurialName, fileData, startDate, endDate)
          }
        />
      ),
    });
  }

  // Action to do after the first modal (go to step 2)
  onCompleteStep1(mercurialName, file, startDate, endDate) {
    // Parse the excel file and then move on to next modal
    ExcelUtil.parse(file, (fileData) => {
      // Save the passed params
      this.setState({
        mercurialName: mercurialName,
        fileName: file.name,
        fileData: fileData,
        startDate: startDate,
        endDate: endDate,
      });

      // Close current modal
      this.closeModal();

      // Open next step modal
      this.setState({
        modal: (
          <MercurialColumnsModal
            closeModal={() => this.closeModal()}
            fileData={fileData}
            onComplete={(columns) => this.onCompleteStep2(columns)}
          />
        ),
      });
    });
  }

  onCompleteStep2(columns) {
    this.setState({
      columns: columns,
    });

    const successCallback = (mercurial) => {
      this.closeModal();
      this.openImgsModal(mercurial);
    };

    this.addMercurial(
      this.state.mercurialName,
      this.state.fileName,
      this.state.fileData,
      this.state.startDate,
      this.state.endDate,
      columns,
      successCallback,
    );
  }

  /**
   * Send a new mercurial to the backend (create mode)
   *
   * @param {*} name
   * @param {*} fileName
   * @param {*} data
   * @param {*} startDate
   * @param {*} endDate
   * @param {*} columns
   * @param {*} callback
   */
  addMercurial(name, fileName, data, startDate, endDate, columns, callback) {
    // First, fix data with correct columns
    var products = this.fixProductsCols(data, columns);

    // Then, send data to the BE
    let mercurial = {
      name: name,
      fileName: fileName,
      startDate: startDate,
      endDate: endDate,
      products: products,
    };
    this.props.onaddMercurial(mercurial, callback);
  }

  // Perform checks on columns <-> data associations for products
  fixProductsCols(products, columns) {
    let newProducts = [];
    for (let p of products) {
      var newProduct = {};
      for (let col of Object.keys(columns)) newProduct[col] = p[columns[col]];
      newProducts.push(newProduct);
    }

    return newProducts;
  }

  openModal(errorModalTitle, errorModalContent) {
    this.setState({
      modal: (
        <AlertModal
          title={errorModalTitle}
          content={errorModalContent}
          onClose={() => this.closeModal()}
          variant="danger"
        />
      ),
    });
  }

  // In mercurial "create" mode, Step 3 is when we let the ability to the user to upload associated categories/products images
  openImgsModal(mercurial, mode) {
    this.setState({
      mode: mode === "update" ? mode : "create",
      modal: (
        <MercurialImgsModal
          closeModal={() => this.closeModal()}
          onComplete={(mercurialId, productImgs, useCustomImage) =>
            this.uploadImgs(mercurialId, productImgs, useCustomImage)
          }
          mercurial={mercurial}
        />
      ),
    });
  }

  // Management of images uploading
  uploadImgs(mercurialId, productImgs, useCustomImage) {
    var sendProductImgs = (successCallback) => {
      if (!productImgs || productImgs.length === 0) return successCallback();

      let formData = new FormData();

      // Tell the backend if custom images must be stored and displayed instead existing images in database
      formData.append("useCustomImage", useCustomImage);

      for (let img of productImgs) formData.append(img.reference, img.file);

      this.props.onAddProductsImgs(mercurialId, formData, successCallback);
    };

    // Notification after image uploading in "create" mode (inform the user a new mercurial have been added)
    const notification = () => {
      Notification({
        type: "success",
        description: this.props.intl.formatMessage(
          this.state.mode === "update"
            ? { id: "Mercurial.Updated" }
            : { id: "Mercurial.Created" },
        ),
      });
    };

    sendProductImgs(() => this.closeModal(notification));
  }

  // Reset all previous import params in state when aborting import process
  abortAndCloseModal() {
    this.setState({
      mercurialName: null,
      fileName: null,
      fileData: null,
      startDate: null,
      endDate: null,
    });

    this.closeModal();
  }

  // Simple close modal function that can handle a possible callback if provided
  closeModal(callback) {
    this.setState({ modal: null }, callback && callback);
  }

  // CRM SYNC MODAL
  openSyncModal(products, crmSoftware, crmEnabled, mercurial) {
    this.setState({
      modal: (
        <MercurialsSyncArticlesModal
          openModal={(errorModalTitle, errorModalContent) =>
            this.openModal(errorModalTitle, errorModalContent)
          }
          products={products}
          crmEnabled={crmEnabled}
          crmSoftware={crmSoftware}
          mercurial={mercurial} //ajout de mercurial pour récupérer les produits
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  // Update mercurials status after an deletion or archive
  updateStatus(status, mercurial) {
    var data = {
      mercurialId: mercurial._id,
      updatedField: "status",
      updatedValue: status,
    };
    this.props.onUpdateMercurialStatus(data);
  }

  openArchivingModal(mercurial) {
    this.setState({
      modal: (
        <MercurialArchiveModal
          mercurial={mercurial}
          updateStatus={(status, mercurial) =>
            this.updateStatus(status, mercurial)
          }
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  openEditModal(mercurial) {
    this.setState({
      modal: (
        <MercurialFileModal
          mercurial={mercurial}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  openDeleteModal(mercurial) {
    const successCallback = () => {
      this.props.onDeleteMercurial({ mercurialId: mercurial._id }, () => {
        Notification({
          type: "success",
          description: this.props.intl.formatMessage({
            id: "Mercurial.Deleted",
          }),
        });

        this.closeModal();
      });
    };

    this.setState({
      modal: (
        <ConfirmationModal
          title={<FormattedMessage id="Warning" />}
          mandatoryConfirmation
          onAccept={successCallback}
          onDecline={() => this.closeModal()}
        >
          <div className="text-justify">
            <Alert variant="danger">
              <FormattedMessage id="Mercurial.Remove.Confirmation" />
              <br />
              <FormattedMessage id="Irrevocable.Warning" />
            </Alert>
          </div>
        </ConfirmationModal>
      ),
    });
  }

  setStartDateFilter = (date) => {
    this.setState({
      startDateFilter: new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        0,
        0,
        0,
        0,
      ),
    });
  };

  setEndDateFilter = (date) => {
    this.setState({
      endDateFilter: new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        23,
        59,
        59,
        999,
      ),
    });
  };

  areResultsFiltered = () => {
    if (
      !Util.emptyString(this.state.nameFilter) ||
      !Util.emptyString(this.state.startDateFilter) ||
      !Util.emptyString(this.state.endDateFilter) ||
      this.state.mercurialsFilter !== "all"
    ) {
      return true;
    } else {
      return false;
    }
  };

  resetSearchFields() {
    this.setState({
      nameFilter: "",
      startDateFilter: "",
      endDateFilter: "",
      mercurialsFilter: "all",
    });
  }

  render() {
    // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
    // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
    const { enabled: crmEnabled = false } = this.props.company.crm
      ? this.props.company.crm
      : {};

    const canManage =
      (!this.props.user.supervisor_id &&
        this.props.user.permissions.can_manage_catalog) ||
      (this.props.user.supervisor_id &&
        this.props.user.has_write_permission);

    if (this.props.mercurials.length === 0) {
      return (
        <TableToolbar message={<FormattedMessage id="Empty.Mercurials" />}>
          {canManage && (
            <MenuButton onClick={(e) => this.openMercurialImportModal(e)}>
              <FormattedMessage id="Mercurials.Add.Some" />
            </MenuButton>
          )}
          {this.state.modal}
        </TableToolbar>
      );
    }

    this.paginator.init(this.props.mercurials.length);

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    var mercurialsNode = this.props.mercurials.map((mercurial) => {
      if (this.state.nameFilter && this.state.nameFilter !== "") {
        if (
          mercurial.name
            .toLowerCase()
            .indexOf(this.state.nameFilter.toLowerCase()) === -1
        )
          return null;
      }

      if (this.state.startDateFilter && this.state.startDateFilter !== "") {
        if (
          new Date(mercurial.start_date) < new Date(this.state.startDateFilter)
        )
          return null;
      }

      if (this.state.endDateFilter && this.state.endDateFilter !== "") {
        if (new Date(mercurial.end_date) > new Date(this.state.endDateFilter))
          return null;
      }

      // Mercurials filter
      if (
        this.state.mercurialsFilter === MercurialStatus.ACTIVE.toString() &&
        (mercurial.status !== MercurialStatus.ACTIVE ||
          (mercurial.end_date &&
            new Date(mercurial.end_date) < new Date(new Date())))
      ) {
        return null;
      } else if (
        this.state.mercurialsFilter === MercurialStatus.INACTIVE.toString() &&
        mercurial.status !== MercurialStatus.INACTIVE &&
        (!mercurial.end_date ||
          new Date(mercurial.end_date) >= new Date(new Date()))
      ) {
        return null;
      }

      if (!this.paginator.keep()) return null;

      return (
        <MercurialRow
          key={mercurial.name + "-" + mercurial.version}
          openSyncModal={(products, crmSoftware, crmEnabled, mercurial) =>
            this.openSyncModal(products, crmSoftware, crmEnabled, mercurial)
          }
          mercurial={mercurial}
          mercurials={this.props.mercurials}
          openImgsModal={(mercurial) => this.openImgsModal(mercurial, "update")}
          openArchivingModal={(mercurial) => this.openArchivingModal(mercurial)}
          openEditModal={(mercurial) => this.openEditModal(mercurial)}
          openDeleteModal={(mercurial) => this.openDeleteModal(mercurial)}
          updateStatus={(status, mercurial) =>
            this.updateStatus(status, mercurial)
          }
          setState={(state) => this.setState(state)}
        />
      );
    });

    if (this.state.mercurialId) {
      return (
        <FamiliesAdmin
          mercurialId={this.state.mercurialId}
          setState={(state) => this.setState(state)}
        />
      );
    }

    return (
      <React.Fragment>
        {!this.props.limit && (
          <TableToolbar>
            <Form.Group as={Row} className="align-items-center">
              <Col>
                <InputTextElement
                  type="text"
                  id="search_mercurial"
                  className={this.state.nameFilter && "filtered-result"}
                  onChange={(e) => {
                    this.setState({ nameFilter: e.target.value });
                  }}
                  placeholder={this.props.intl.formatMessage({
                    id: "Name",
                  })}
                  disabled={disableFormInput}
                  value={this.state.nameFilter}
                />
              </Col>
              <Col>
                <InputTextElement
                  id="search_start_date"
                  type="date"
                  className={this.state.startDateFilter && "filtered-result"}
                  onChange={(e) => {
                    this.setStartDateFilter(e.target.value);
                  }}
                  disabled={disableFormInput}
                  value={DateUtil.toyyyyMMdd(this.state.startDateFilter)}
                  placeholder={this.props.intl.formatMessage({
                    id: "Start.Date",
                  })}
                />
              </Col>
              <Col>
                <InputTextElement
                  id="search_end_date"
                  type="date"
                  className={this.state.endDateFilter && "filtered-result"}
                  onChange={(e) => {
                    this.setEndDateFilter(e.target.value);
                  }}
                  disabled={disableFormInput}
                  value={DateUtil.toyyyyMMdd(this.state.endDateFilter)}
                />
              </Col>
              <Col>
                <FormSelect
                  className={
                    this.state.mercurialsFilter !== "all" && "filtered-result"
                  }
                  value={this.state.mercurialsFilter}
                  onChange={(e) =>
                    this.setState({ mercurialsFilter: e.target.value })
                  }
                  disabled={disableFormInput}
                >
                  <option value="">
                    <>
                      {this.props.intl.formatMessage({ id: "Display" })}
                      {": "}
                      {this.props.intl.formatMessage({ id: "All" })}
                    </>
                  </option>
                  <option
                    key={MercurialStatus.ACTIVE}
                    value={MercurialStatus.ACTIVE}
                  >
                    <>
                      {this.props.intl.formatMessage({ id: "Display" })}
                      {": "}
                      {this.props.intl.formatMessage({
                        id: "Mercurial.Status.0.s",
                      })}
                    </>
                  </option>
                  <option
                    key={MercurialStatus.INACTIVE}
                    value={MercurialStatus.INACTIVE}
                  >
                    <>
                      {this.props.intl.formatMessage({ id: "Display" })}
                      {": "}
                      {this.props.intl.formatMessage({
                        id: "Mercurial.Status.1.s",
                      })}
                    </>
                  </option>
                </FormSelect>
              </Col>
              <Col>
                <MenuButton
                  onClick={() => this.resetSearchFields()}
                  hover={
                    this.areResultsFiltered() &&
                    !disableFormInput && <FormattedMessage id="Remove.Filter" />
                  }
                  variant={
                    this.areResultsFiltered() ? "info" : "outline-secondary"
                  }
                  icon="filter"
                  disabled={!this.areResultsFiltered() || disableFormInput}
                />
              </Col>
              {canManage && (
                <Col className="text-end">
                  <MenuButton onClick={(e) => this.openMercurialImportModal(e)}>
                    <FormattedMessage id="Mercurials.Add.Some" />
                  </MenuButton>
                </Col>
              )}
            </Form.Group>
          </TableToolbar>
        )}

        <table className="table table-striped tablee4mad">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="Name" />
              </th>
              <th scope="col">
                <FormattedMessage id="Start.Date" />
              </th>
              <th scope="col">
                <FormattedMessage id="End.Date" />
              </th>
              <th scope="col">
                <FormattedMessage id="File" />
              </th>
              <th scope="col">
                <FormattedMessage id="Status" />
              </th>
              {crmEnabled && (
                <th scope="col">
                  <FormattedMessage id="API.CRM.Sync.Short" />
                </th>
              )}
              <th scope="col" className="col-auto text-center">
                <FormattedMessage id="Actions" />
              </th>
            </tr>
          </thead>
          <tbody>{mercurialsNode}</tbody>
        </table>

        {this.paginator.render()}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mercurials: state.mercurials,
    products: state.products,
    company: state.company,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteMercurial: (data, successCallback) =>
      dispatch(deleteMercurial(data, successCallback)),
    onUpdateMercurialStatus: (data) => dispatch(updateMercurialStatus(data)),
    onaddMercurial: (data, successCallback) =>
      dispatch(addMercurial(data, successCallback)),
    onAddProductsImgs: (mercurialId, data, successCallback) =>
      dispatch(addProductsImgs(mercurialId, data, successCallback)),
    onAddFamilyImgs: (mercurialId, data, successCallback) =>
      dispatch(addFamilyImg(mercurialId, data, successCallback)),
    onFindArticlesMust: (data, successCallback, failureCallback) =>
      dispatch(findArticlesMust(data, successCallback, failureCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Mercurials));
